import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react';

const ScannerComponent = ({ apiEndpoint }) => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const response = await fetch(apiEndpoint);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStocks(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch stock data');
        setLoading(false);
      }
    };

    fetchStocks();
  }, [apiEndpoint]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }

    const sortedStocks = [...stocks].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setStocks(sortedStocks);
  };

  const SortableTableHead = ({ column, children }) => {
    const isSorted = sortColumn === column;
    let SortIcon = ArrowUpDown;
    if (isSorted) {
        SortIcon = sortDirection === 'asc' ? ArrowDown : ArrowUp;
    }

    return (
        <TableHead
            className="cursor-pointer"
            onClick={() => handleSort(column)}
        >
            <div className="flex items-center">
                {children}
                <SortIcon className="ml-2 h-4 w-4" />
            </div>
        </TableHead>
    );
};

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <Table>
        <TableHeader>
          <TableRow className = 'bg-custom-table-header-dark text-white hover:bg-custom-table-bg-hover'>
            <SortableTableHead column="symbol">Symbol</SortableTableHead>
            <SortableTableHead column="companyName">Company Name</SortableTableHead>
            <SortableTableHead column="sector">Sector</SortableTableHead>
            <SortableTableHead column="industry">Industry</SortableTableHead>
            <SortableTableHead column="marketCap">Market Cap</SortableTableHead>
            <SortableTableHead column="price">Price</SortableTableHead>
            <SortableTableHead column="change">Change</SortableTableHead>
            <SortableTableHead column="volume">Volume</SortableTableHead>
            <SortableTableHead column="scanner">Scanner</SortableTableHead>
            <SortableTableHead column="first_appeared">First Appeared</SortableTableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {stocks.map((stock, index) => (
            <TableRow 
                key={stock.id}
                className={`${index % 2 === 0 ? 'bg-custom-table-bg-dark' : 'bg-custom-table-bg-light'} text-white hover:bg-custom-table-bg-hover`}>
              <TableCell>{stock.symbol}</TableCell>
              <TableCell>{stock.companyName}</TableCell>
              <TableCell>{stock.sector}</TableCell>
              <TableCell>{stock.industry}</TableCell>
              <TableCell>${(stock.marketCap / 1e9).toFixed(2)}B</TableCell>
              <TableCell>${stock.price.toFixed(2)}</TableCell>
              <TableCell className={stock.change >= 0 ? 'text-green-600' : 'text-red-600'}>
                {stock.change.toFixed(2)}%
              </TableCell>
              <TableCell>{stock.volume.toLocaleString()}</TableCell>
              <TableCell>{stock.scanner}</TableCell>
              <TableCell>{stock.first_appeared}</TableCell>           
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ScannerComponent;